import React, { useState } from "react";
import PropTypes from "prop-types";
import closeIcon from "../assets/icons/close.svg";
import FilterList from "./FilterList";

const FilterOverlay = ({
  show,
  onClose,
  items,
  selectedItem,
  setSelectedItem,
  teamOptions,
  selectedTeam,
  setSelectedTeam,
  dates,
  selectedDate,
  setSelectedDate,
}) => {
  const [showItemList, setShowItemList] = useState(false);
  const [showDateList, setShowDateList] = useState(false);
  const [showTeamList, setShowTeamList] = useState(false);

  const isItemSelected = selectedItem !== "所有項目";
  const isDateSelected = selectedDate !== dates[0]?.date;
  const isTeamSelected = selectedTeam !== "所有隊伍";

  return (
    <div className={`filter-overlay ${show ? "show" : ""}`}>
      <div className="filter-overlay-header">
        <div className="filter-overlay-title">篩選</div>
        <img src={closeIcon} alt="Close" onClick={onClose} />
      </div>
      <div className="filter-overlay-content">
        <div
          className={`filter-overlay-item ${isItemSelected ? "selected" : ""}`}
          onClick={() => setShowItemList(true)}
          style={{
            backgroundColor: isItemSelected ? "#0a7deb" : "",
            color: isItemSelected ? "#fff" : "",
            opacity: showItemList || showDateList || showTeamList ? 0.5 : 1,
            pointerEvents: showItemList || showDateList || showTeamList ? "none" : "auto",
          }}
        >
          {isItemSelected ? selectedItem : "所有項目"}
        </div>
        {/* <div
          className={`filter-overlay-item ${isDateSelected ? "selected" : ""}`}
          onClick={() => setShowDateList(true)}
          style={{
            backgroundColor: isDateSelected ? "#0a7deb" : "",
            color: isDateSelected ? "#fff" : "",
            opacity: showItemList || showDateList || showTeamList ? 0.5 : 1,
            pointerEvents: showItemList || showDateList || showTeamList ? "none" : "auto",
          }}
        >
          <span>所有日期</span>
        </div> */}
        <div
          className={`filter-overlay-item ${isTeamSelected ? "selected" : ""}`}
          onClick={() => setShowTeamList(true)}
          style={{
            backgroundColor: isTeamSelected ? "#0a7deb" : "",
            color: isTeamSelected ? "#fff" : "",
            opacity: showItemList || showDateList || showTeamList ? 0.5 : 1,
            pointerEvents: showItemList || showDateList || showTeamList ? "none" : "auto",
          }}
        >
          {isTeamSelected ? selectedTeam : "所有隊伍"}
        </div>
        <button
          className="clear-filters"
          onClick={() => {
            setSelectedItem("所有項目");
            setSelectedTeam("所有隊伍");
            setSelectedDate(dates[0].date);
          }}
        >
          <span>清除選項</span>
        </button>
      </div>

      <FilterList
        show={showItemList}
        title="所有項目"
        items={items}
        selectedItem={selectedItem}
        onSelect={(item) => {
          setSelectedItem(item);
          setShowItemList(false);
        }}
        onBack={() => setShowItemList(false)}
        onClose={onClose}
      />

      {/* <FilterList
        show={showDateList}
        title="所有日期"
        items={dates.map((date) => date.date)}
        selectedItem={selectedDate}
        onSelect={(date) => {
          setSelectedDate(date);
          setShowDateList(false);
        }}
        onBack={() => setShowDateList(false)}
        onClose={onClose}
      /> */}

      <FilterList
        show={showTeamList}
        title="所有隊伍"
        items={teamOptions}
        selectedItem={selectedTeam}
        onSelect={(team) => {
          setSelectedTeam(team);
          setShowTeamList(false);
        }}
        onBack={() => setShowTeamList(false)}
        onClose={onClose}
      />
    </div>
  );
};

FilterOverlay.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItem: PropTypes.string.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  teamOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTeam: PropTypes.string.isRequired,
  setSelectedTeam: PropTypes.func.isRequired,
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedDate: PropTypes.string.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
};

export default FilterOverlay;
