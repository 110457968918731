export const EpisodeType = {
  MASTER: 'master',
  TRAILER: 'trailer',
  MARKING_OF: 'makingof',
  INTERVIEW: 'interview',
  COMING_SOON: 'comingsoon',
}

export const EpisodeClassification = {
  I: 'I',
  IIA: 'IIA',
  IIB: 'IIB',
  III: 'III',
}
