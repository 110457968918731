import React from "react";

import { useVideoContext } from "../Contexts/VideoContext";

const formattedTimeStamp = (durationInSecond) => {
  // output format hh:mm:ss
  return new Date(durationInSecond * 1000).toISOString().slice(11, 19);
};

const VideoCard = ({ featured, ...videoBundle }) => {
  const { video, programme } = videoBundle;

  const imageUrl = video.avatar;
  const title = video.episodeNum + " | " + video.episodeNameU3;
  const duration = formattedTimeStamp(video.totalDurationSec);
  const { setVideoBundle } = useVideoContext();

  const handleClick = () => {
    if (!video || !programme) {
      console.error("Error: No video or programme found");
      return;
    }
    setVideoBundle(videoBundle);
  };

  return (
    <div
      className={`video-card ${
        featured ? "featured-video-item" : "video-item"
      }`}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <div
        className={`${
          featured
            ? "featured-video-item-img-container"
            : "video-item-img-container"
        }`}
        style={{ position: "relative" }}
      >
        <img
          src={imageUrl}
          className={`card-img-top ${
            featured ? "video-featured-img" : "video-item-img"
          }`}
          alt={title}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        <div className="video-duration">
          <div className="video-duration-num">{duration}</div>
        </div>
      </div>
      <div
        className={`${
          featured
            ? "featured-video-item-text-container"
            : "video-item-text-container"
        }`}
      >
        <p className="card-text">{title}</p>
      </div>
    </div>
  );
};

export default VideoCard;
