import HomePage from "../Pages/HomePage";
import LivePage from "../Pages/LivePage";
import EncorePage from "../Pages/EncorePage";
import SportsPage from "../Pages/SportsPage";
import SportDetailPage from "../Pages/SportDetailPage";
import SchedulePage from "../Pages/SchedulePage";
import CampaignPage from "../Pages/CampaignPage";
import HKTeamPage from "../Pages/HKTeamPage";
import MedalTablePage from "../Pages/MedalTablePage";
import CountDownPage from "../Pages/CountDownPage";
import MobileMenuPage from "../Pages/Mobile/MobileMenuPage";

export const RoutesConfig = [
  { path: "/", element: <CountDownPage /> },
  { path: "/home", element: <HomePage /> },
  { path: "/live", element: <LivePage /> },
  { path: "/encore", element: <EncorePage /> },
  { path: "/sports", element: <SportsPage /> },
  { path: "/sports/:sport_enum", element: <SportDetailPage /> },
  { path: "/sports/:sport_enum?tab=encore", element: <SportDetailPage /> },
  { path: "/sports/:sport_enum?tab=schedule", element: <SportDetailPage /> },
  { path: "/schedule", element: <SchedulePage /> },
  { path: "/schedule?channel=:channel&sport=:sport_enum&date=:date&team=:team", element: <SchedulePage /> },
  { path: "/campaign", element: <CampaignPage /> },
  { path: "/hkteam", element: <HKTeamPage /> },
  { path: "/hkteam?tab=encore", element: <HKTeamPage /> },
  { path: "/hkteam?tab=schedule", element: <HKTeamPage /> },
  { path: "/medal-table", element: <MedalTablePage /> },
  { path: "/mobile-menu", element: <MobileMenuPage /> },
];
