import { LOCALE_SUB_TAG } from '../Constants/locale';
import { getAdaptiveImageData } from './Image';
import { getUid } from './Session';
import { getEpisodeTrackingTitle } from './Tracking';

const appendUrlParam = (url, paramName, paramValue) => {
  const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
  if (url.search(pattern) >= 0) {
    let originalParamsValue =
      pattern.exec(url)?.[0].replace(pattern.exec(url)?.[1] ?? '', '') ?? null;
    if (originalParamsValue?.endsWith('&')) {
      // remove the trailing & if params is in the middle of query string
      originalParamsValue = originalParamsValue.slice(0, -1);
    }
    const newParams = originalParamsValue + paramValue;
    return url.replace(pattern, '$1' + newParams + '$2');
  }
  return url;
};

export const getTargetedAdSpec = (
  rolls,
  adTarget
) => {
  if (!adTarget || !Array.isArray(rolls)) return rolls;
  let adTargetSubString = '';
  for (const [key, value] of Object.entries(adTarget)) {
    adTargetSubString =
      adTargetSubString + `${encodeURIComponent(`&${key}=${value}`)}`;
  }
  return rolls.map((roll) => ({
    tag: appendUrlParam(roll.tag, 'cust_params', adTargetSubString),
  }));
};

export const getVideoVodMetaFromEpisodeAndProgramme = (options) => {
  const {
    localeSubTag = LOCALE_SUB_TAG.ZH_HK,
    programme,
    episode,
    initialStartTime,
  } = options;
  if (!episode) return;

  const vodMeta = {
    seq: null,
    raw: {
      episode,
    },

    ...(initialStartTime && { initialStartTime }),

    // programme related
    videoTitle: `${programme.sponsor ? `${programme.sponsor}：` : ''}${
      episode.program_title
    }`,
    // episode related
    productId: episode.productId,
    productSubtitle: episode.productSubtitle,
    videoDescription:
      episode[localeSubTag]?.episodeNameU3 ?? episode.episodeNameU3,

    videoPreviewImage:
      getAdaptiveImageData(programme.landscapeImage)?.sizesMap?.[1024] ??
      programme.landscapeImage,
    // ad related
    enableAdSchedule: true,
    adSpec: {
      prerolls: getTargetedAdSpec(
        programme.ad_spec.web.prerolls,
        episode.ad_target // aggregate ad_target from episode and programme
      ),
      midrolls: getTargetedAdSpec(
        programme.ad_spec.web.midrolls,
        episode.ad_target // aggregate ad_target from episode and programme
      ),
      postrolls: getTargetedAdSpec(
        programme.ad_spec.web.postrolls,
        episode.ad_target // aggregate ad_target from episode and programme
      ),
    },
    adBreaks: episode.midroll_adbreaks,
    // drm related
    // fairplayCertURL: '', // can override the one in PlayerManager
    youbora: getYouboraPayload({ episode })?.payload,
  };

  return vodMeta;
};

export const getVideoCoverInfoFromEpisode = (episode) => {
  if (!episode) return null;

  const image =
    getAdaptiveImageData(episode.avatar).sizesMap?.[800] ??
    episode.program_landscapeImage;

  return {
    image,
    subtitle: `第${episode.episodeNum}集`,
    title:
      episode[LOCALE_SUB_TAG.ZH_HK]?.episodeNameU3 ?? episode.episodeNameU3,
    description: episode[LOCALE_SUB_TAG.ZH_HK]?.synopsis ?? episode.synopsis,
    labels: ['奧運2024'],
  };
};

export const getYouboraPayload = (options) => {
  const { episode } = options;

  if (!episode) return null;

  const customDimensions = {
    1: episode.episodeNum,
    2: episode.productHktamCleanVersionId,
    ...(episode.programmeMeta && {
      3: episode.programmeMeta.episodesCount,
      6: episode.programmeMeta.type?.map?.((tag) => tag.name),
      7: episode.programmeMeta.tags?.map?.((tag) => tag.name),
      8: episode.programmeMeta.nature?.map?.((tag) => tag.name),
      9: episode.programmeMeta.producer?.map?.((tag) => tag.name),
      10: episode.programmeMeta.genre?.map?.((tag) => tag.name),
      11: episode.programmeMeta.director?.map?.((tag) => tag.name),
      12: episode.programmeMeta.actors?.map?.((tag) => tag.name),
    }),
    ...(episode.videoMeta && {
      4: episode.videoMeta.tags?.map?.((tag) => tag.name),
      5: episode.videoMeta.actors?.map?.((tag) => tag.name),
    }),
  };

  const appValues = {
    name: 'ViuTV',
    releaseVersion: '3.0.0-OLY2024',
  };

  const appKeyValues = {
    'app.name': appValues.name,
    'app.releaseVersion': appValues.releaseVersion,
  };

  const contentValues = {
    title: `${episode.program_title}(${
      episode.episodeNum
    }) - ${getEpisodeTrackingTitle(episode)}`,
    episodeTitle: getEpisodeTrackingTitle(episode),
    id: episode.mediaId,
    program: episode.program_title,
    tvShow: episode.program_title,
    isLive: false,
    season: episode.programmeMeta?.seasonNo,
  };

  const contentKeyValues = {
    'content.title': contentValues.title,
    'content.episodeTitle': contentValues.episodeTitle,
    'content.id': contentValues.id,
    'content.program': contentValues.program,
    'content.tvShow': contentValues.tvShow,
    'content.isLive': contentValues.isLive,
    'content.season': contentValues.season,
  };

  return {
    appValues,
    contentValues,
    customDimensions,
    appKeyValues,
    contentKeyValues,
    payload: {
      username: getUid(),
      ...appKeyValues,
      ...contentKeyValues,
      // 'content.language': 'language 1', // handled in CustomYouboraPlugin
      // 'content.subtitles': '' // handled in CustomYouboraPlugin
      // 'content.resource': '', // handled in CustomYouboraPlugin
      'content.customDimension.1': episode.episodeNum,
      'content.customDimension.2': customDimensions['2'],
      ...(episode.programmeMeta && {
        'content.customDimension.3': customDimensions['3'],
        'content.customDimension.6': customDimensions['6'],
        'content.customDimension.7': customDimensions['7'],
        'content.customDimension.8': customDimensions['8'],
        'content.customDimension.9': customDimensions['9'],
        'content.customDimension.10': customDimensions['10'],
        'content.customDimension.11': customDimensions['11'],
        'content.customDimension.12': customDimensions['12'],
      }),
      ...(episode.videoMeta && {
        'content.customDimension.4': customDimensions['4'],
        'content.customDimension.5': customDimensions['5'],
      }),
    },
  };
};
