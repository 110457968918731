import { UNKNOWN_VALUE } from '../Constants/tracking';

import usePlayerTracking from './usePlayerTracking';
import { useVideoContext } from '../Contexts/VideoContext';
import { getVideoInfoFromVideo } from '../Utils/Tracking';

const useVideoTracking = (props) => {
  const { playerRef, programme } = props;
  const { videoBundle } = useVideoContext();

  const currentVideo = videoBundle?.video;

  const _getPayloadFn = () => {
    if (!currentVideo) return null;

    const videoInfo = getVideoInfoFromVideo(currentVideo);

    return {
      video_info: videoInfo.zh,
      video_info_en: videoInfo.en,
    };
  };

  const { onTime, getTimestamp, getTrackingPayload, lastPositionRef } =
    usePlayerTracking({ playerRef, getPayloadFn: _getPayloadFn });

  const getCommonTrackingPayload = (payload) =>
    getTrackingPayload(payload);

  const getReactionTrackingPayload = (payload) => {
    return {
      item_info: programme?.zh_HK?.title ?? UNKNOWN_VALUE,
      item_info_en: programme?.en_HK?.title ?? UNKNOWN_VALUE,
      item_url: currentVideo?.slug ?? UNKNOWN_VALUE,
      episode_no: currentVideo?.episodeNum ?? UNKNOWN_VALUE,
      episode_title: currentVideo?.zh_HK.episodeNameU3 ?? UNKNOWN_VALUE,
      video_timestamp: getTimestamp(payload),
    };
  };

  return {
    getCommonTrackingPayload,
    getReactionTrackingPayload,
    onTime,
    lastPositionRef,
  };
};

export default useVideoTracking;
