import React, { createContext, useState, useEffect } from "react";

export const KeyVisualContext = createContext();

export const KeyVisualProvider = ({ children }) => {
  const [keyVisualImage, setKeyVisualImage] = useState("");
  const [adImage, setAdImage] = useState("");
  const [data, setData] = useState(null);

  const fetchData = async (url, fallbackUrl) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      if (Object.keys(data).length === 0) throw new Error("Empty data");
      return data;
    } catch (error) {
      console.error(`Failed to fetch from ${url}, using fallback: ${error}`);
      const fallbackResponse = await fetch(fallbackUrl);
      return fallbackResponse.json();
    }
  };

  const isMobile = () => window.innerWidth <= 768;

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchData(
        "https://olympics-minisite-2024-dev.viu.tv/json/stage1.json",
        // `${process.env.PUBLIC_URL}/api/mock_stage1.json`
      );
      setData(fetchedData);

      const updateImages = () => {
        if (isMobile()) {
          setKeyVisualImage(fetchedData.data.keyVisual.mobile);
          setAdImage(fetchedData.data.banner.mobile);
        } else {
          setKeyVisualImage(fetchedData.data.keyVisual.desktop);
          setAdImage(fetchedData.data.banner.desktop);
        }
      };

      updateImages();
      window.addEventListener("resize", updateImages);

      return () => window.removeEventListener("resize", updateImages);
    };

    loadData();
  }, []);

  return (
    <KeyVisualContext.Provider
      value={{ keyVisualImage, adImage, data }}
    >
      {children}
    </KeyVisualContext.Provider>
  );
};
