import classNames from "classnames";

import React, { useContext } from "react";

import getFormattedDate from "../../Utils/Helpers"

import black_logo from "../../assets/images/black_logo.png";
import closeIcon from "../../assets/icons/close_small.svg";
import pipIcon from "../../assets/icons/pip.svg";

import { useVideoContext } from "../../Contexts/VideoContext";
import DataTrackingVodPlayer from "./DataTrackingVodPlayer";

import { KeyVisualContext } from "../../Contexts/KeyVisualContext";

const OverlayVideoPlayer = () => {
  const { videoBundle, closeOverlayPlayer, isShowOverlayPlayer, vodItem } =
    useVideoContext();

  const { keyVisualImage, adImage } = useContext(KeyVisualContext);

  if (!isShowOverlayPlayer || !videoBundle) return null;

  const {
    video,
    programme,
    description,
    eventDate,
    // includeChina,
    // includeHK,
    // sports,
    // tags,
    teams,
  } = videoBundle || {};

  const isDataValid = Boolean(video && programme && vodItem);

  const monthDay = eventDate && getFormattedDate(eventDate)?.monthDay;

  return (
    <div className={classNames("olp", "olp__wrapper")}>
      <div className="olp__top">
        <div className="logo-container logo-container-black">
          <img src={black_logo} alt="black_logo" className="logo" />
        </div>
        <div className="olp__close-btn olp__btn" onClick={() => closeOverlayPlayer()}>
          <img src={closeIcon} alt="close-btn" className="close_btn" />
        </div>
      </div>
      <div className="olp__body">
        <div className="olp__top-ad">
        {adImage && (
            <div className="Advertisement">
              <img className="adv-img" src={adImage} alt="Advertisement" />
            </div>
          )}
        </div>
        {isDataValid ? (
          <>
            <div className="olp__video-info">
              <div className="olp__video-title">{description || video?.episodeNameU3}</div>
              {eventDate && (
                <div className="olp__event-date">
                  <span>{monthDay}</span>
                </div>
              )}
              {teams && (
                <div className="olp__teams">
                  <span>{teams.join(" VS ")}</span>
                </div>
              )}
            </div>
            <div className="olp__container">
              <DataTrackingVodPlayer />
              {/* or without data tracking */}
              {/* <VodPlayer /> */}
            </div>
          </>
        ) : (
          <div>No video found (TODO)</div>
        )}
      </div>
    </div>
  );
};

export default OverlayVideoPlayer;
