/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
const LogType = {
  DEBUG: 'debug',
  INFO: 'info',
  LOG: 'log',
  WARN: 'warn',
  ERROR: 'error',
}

const consoleFactory = (type) => {
  return (...params) => {
    const isAllString = params.every((item) => typeof item === 'string');
    return isAllString
      ? console[type](
          new Array(params.length).fill(`%c%s`).join(''),
          ...params.flatMap((str, idx) => [
            idx % 2 ? `color: deepskyblue;` : `color: cyan;`,
            str,
          ])
        )
      : console[type](...params);
  };
};

export const dConsole = {
  ...console,
  debug: consoleFactory(LogType.DEBUG),
  info: consoleFactory(LogType.INFO),
  log: consoleFactory(LogType.LOG),
  warn: consoleFactory(LogType.WARN),
  error: consoleFactory(LogType.ERROR),
};


const getDebugFlagsFromSearchOrWinObj = () => {
  if (typeof window === 'undefined') return;

  const searchParams = new URLSearchParams(window.location.search);
  const flagsFromSearch = searchParams.get('viutvdebug')?.split(',');

  window.__VIUTV_DEBUG_DATA__ = window.__VIUTV_DEBUG_DATA__ || {};
  window.__VIUTV_DEBUG_DATA__.debugFlags = [
    ...new Set([
      ...(window.__VIUTV_DEBUG_DATA__.debugFlags || []),
      ...(flagsFromSearch || []),
    ]),
  ];

  return window.__VIUTV_DEBUG_DATA__.debugFlags;
};

export const getIsEnabledDebugFlags = (flagsInput) => {
  const flags = Array.isArray(flagsInput) ? flagsInput : [flagsInput];

  const currentFlags = getDebugFlagsFromSearchOrWinObj();

  return flags.every((flag) => currentFlags?.includes(flag));
};


export const debugGaCustomEventSent = (
  eventName,
  eventParams
) => {
  const logKY = {
    event_name: eventName,
    ...Object.fromEntries(
      Object.entries(eventParams).map(([key, value]) => [
        `event_param: ${key}`,
        value,
      ])
    ),
  };

  dConsole.group(`debug::gaCustomEvent::${eventName}`);
  dConsole.table(logKY);
  dConsole.info('params', eventParams);
  dConsole.groupEnd();
};
