// IMPORTANT
// FIXME need to init gtag manager to use GA4 tracking

export const sendGAEvent = (event_name, data) => {
  if (window.gtag === undefined) {
    console.warn(`Tracking: GA has not been initialized`);
    return;
  }
  if (window.gtag) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.gtag('event', event_name, data);
  } else {
    console.warn(`Tracking: GA Tag does not exist`);
  }
};
