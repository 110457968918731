// FIXME not compatible with the breakpoints of this project

export const SCREEN = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
};

export const BREAKPOINT = {
  XS_MIN: 0,
  SM_MIN: 570,
  MD_MIN: 768,
  LG_MIN: 1024,
};
