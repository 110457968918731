import React, { useState, useRef, useEffect } from "react";
import "../index.css";
import { ReactComponent as DownIcon } from "../assets/icons/down.svg";
import { ReactComponent as TickIcon } from "../assets/icons/tick.svg"; // Ensure you have a tick icon

const CustomDropdown = ({ title, items, selectedItem, onSelect, onOpenDropDown, maxDisplayItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const dropdownRef = useRef(null);

  const handleButtonClick = () => {
    onOpenDropDown(items, !isOpen);
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    onSelect(item); // Only one item can be selected
    setIsOpen(false); // Collapse the dropdown when an item is selected
    setIsExpanded(false); // Reset expansion when item is selected
  };

  const handleExpandClick = () => {
    setIsExpanded(true);
  };

  // Rotate the DownIcon when it is open
  const arrowStyle = isOpen ? { transform: 'rotate(180deg)' } : {};

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setIsExpanded(false); // Reset expansion when clicking outside
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="custom-dropdown-button" onClick={handleButtonClick}>
        {title}
        <DownIcon className="custom-dropdown-icon" style={arrowStyle} />
      </div>
      {isOpen && (
        <div className="custom-dropdown-menu">
          {items.slice(0, isExpanded ? items.length : maxDisplayItems).map((item, index) => (
            <div
              key={item}
              className={`custom-dropdown-item ${selectedItem === item ? "selected" : ""}`}
              onClick={() => handleItemClick(item)}
            >
              {item}
              {selectedItem === item && <TickIcon className="tick-icon" />}
            </div>
          ))}
          {!isExpanded && items.length > maxDisplayItems && (
            <div
              className="custom-dropdown-item"
              onClick={handleExpandClick}
            >
              ...
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
