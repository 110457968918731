// src/components/Footer.js
import React from "react";

const Footer = () => {
  const openPopup = (url, name) => {
    const newWindow = window.open(url, name);
    newWindow.focus();
  };

  const handleClick = (event, url, name) => {
    event.preventDefault();
    openPopup(url, name);
  };

  return (
    <div className="Footer">
      <div className="copyright-container">
        <p className="copyright">© 2024 HKTVE. All rights reserved</p>
      </div>
      <div className="right-nav-container">
        <a href="" onClick={(event) => handleClick(event, "https://viu.tv/terms-condition", "Terms and Conditions")}>
          條款細則
        </a>
        <a href="" onClick={(event) => handleClick(event, "https://viu.tv/our-policy", "Privacy Policy")}>
          私隱政策
        </a>
      </div>
    </div>
  );
};

export default Footer;
