import { sendGACustomEvent } from "../../../../Helpers/ga4Helper";

import { GA4CustomEventContainer } from "../types/container";
import { GA4CustomEvent } from "../types/event";
import { PlayerVolumeStatus } from "../types/payload/change_player_volume";
import { ScreenMode } from "../types/payload/change_screen_mode";
import * as playerTrack from "./player";

const container_title = GA4CustomEventContainer.VOD_PLAYER;

export const trackPlayerLoadStart = (params) =>
  playerTrack.trackPlayerLoadStart(container_title, params);

export const trackPlayerLoadComplete = (params) =>
  playerTrack.trackPlayerLoadComplete(container_title, params);

export const trackPlayerAdRequest = (params) =>
  playerTrack.trackPlayerAdRequest(container_title, params);

export const trackPlayerAdPlay = (params) =>
  playerTrack.trackPlayerAdPlay(container_title, params);

export const trackPlayerVideoPlay = ({ ...params }) =>
  playerTrack.trackPlayerVideoPlay(container_title, params);

export const trackPlayerVideoPause = ({ ...params }) =>
  playerTrack.trackPlayerVideoPause(container_title, params);

export const trackPlayerVideoBuffer = (params) =>
  playerTrack.trackPlayerVideoBuffer(container_title, params);

export const trackPlayerVideoComplete = ({ ...params }) =>
  playerTrack.trackPlayerVideoComplete(container_title, params);

export const trackPlayerVideoTerminate = ({ ...params }) =>
  playerTrack.trackPlayerVideoTerminate(container_title, params);

export const trackPlayerVideoError = (params) =>
  playerTrack.trackPlayerVideoError(container_title, params);

export const trackChangeAudio = ({ ...params }) =>
  playerTrack.trackChangeAudio(container_title, params);

export const trackClickPlayerNext = ({ ...params }) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.CLICK_PLAYER_NEXT,
    ...params,
  };

  sendGACustomEvent({ payload: payload });
};

export const trackClickPlayerPause = ({ ...params }) =>
  playerTrack.trackClickPlayerPause(container_title, params);

export const trackClickPlayerPlay = ({ ...params }) =>
  playerTrack.trackClickPlayerPlay(container_title, params);

export const trackClickPlayerResume = ({ ...params }) =>
  playerTrack.trackClickPlayerResume(container_title, params);

export const trackVideoSeek = ({ ...params }) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.VIDEO_SEEK,
    ...params,
  };
  sendGACustomEvent({ payload: payload });
};

export const trackChangePlaybackSpeed = ({ ...params }) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.CHANGE_PLAYBACK_SPEED,
    ...params,
  };
  sendGACustomEvent({ payload: payload });
};

export { ScreenMode as SCREEN_MODE };

export const trackChangeScreenMode = ({ ...params }) =>
  playerTrack.trackChangeScreenMode(container_title, params);

export const trackChangeSubtitle = ({ ...params }) => {
  const payload = {
    event_name: GA4CustomEvent.CHANGE_SUBTITLE,
    container_title,
    ...params,
  };
  sendGACustomEvent({ payload: payload });
};

export const trackPlayerRewind = ({ ...params }) => {
  const payload = {
    event_name: GA4CustomEvent.PLAYER_REWIND_VIDEO,
    container_title,
    ...params,
  };
  sendGACustomEvent({ payload: payload });
};

export const trackPlayerFastForward = ({ ...params }) => {
  const payload = {
    event_name: GA4CustomEvent.PLAYER_FAST_FORWARD_VIDEO,
    container_title,
    ...params,
  };
  sendGACustomEvent({ payload: payload });
};

export { PlayerVolumeStatus as PLAYER_VOLUME };

export const trackChangeVolume = ({ ...params }) =>
  playerTrack.trackChangeVolume(container_title, params);
