import { sendGACustomEvent } from '../../../../Helpers/ga4Helper';
import { GA4CustomEvent } from '../types/event';

export const trackPlayerLoadStart = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_LOAD_START,
    ...params,
  };

  sendGACustomEvent({ payload });
};

export const trackPlayerLoadComplete = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_LOAD_COMPLETE,
    ...params,
  };

  sendGACustomEvent({ payload });
};

export const trackPlayerAdRequest = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_AD_REQUEST,
    ...params,
  };

  sendGACustomEvent({ payload });
};

export const trackPlayerAdPlay = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_AD_PLAY,
    ...params,
  };

  sendGACustomEvent({ payload });
};

export const trackPlayerVideoPlay = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_VIDEO_PLAY,
    ...params,
  };

  sendGACustomEvent({ payload: payload });
};

export const trackPlayerVideoPause = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_VIDEO_PAUSE,
    ...params,
  };

  sendGACustomEvent({ payload: payload });
};

export const trackPlayerVideoBuffer = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_VIDEO_BUFFER,
    ...params,
  };

  sendGACustomEvent({ payload });
};

export const trackPlayerVideoComplete = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_VIDEO_COMPLETE,
    ...params,
  };

  sendGACustomEvent({ payload });
};

export const trackPlayerVideoTerminate = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_VIDEO_TERMINATE,
    ...params,
  };

  sendGACustomEvent({ payload: payload });
};

export const trackPlayerVideoError = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.PLAYER_VIDEO_ERROR,
    ...params,
  };

  sendGACustomEvent({ payload });
};

export const trackClickPlayerPause = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.CLICK_PLAYER_PAUSE,
    ...params,
  };
  sendGACustomEvent({ payload: payload });
};

export const trackClickPlayerPlay = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.CLICK_PLAYER_PLAY,
    ...params,
  };
  sendGACustomEvent({ payload: payload });
};

export const trackClickPlayerResume = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.CLICK_PLAYER_RESUME,
    ...params,
  };
  sendGACustomEvent({ payload: payload });
};

export const trackChangeScreenMode = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.CHANGE_SCREEN_MODE,
    ...params,
  };

  sendGACustomEvent({ payload: payload });
};

export const trackChangeVolume = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.CHANGE_VOLUME,
    ...params,
  };
  sendGACustomEvent({ payload: payload });
};

export const trackChangeAudio = (
  container_title,
  params
) => {
  const payload = {
    container_title,
    event_name: GA4CustomEvent.CHANGE_AUDIO,
    ...params,
  };

  sendGACustomEvent({ payload: payload });
};
