export const getSmallNumInChineseWord = (num) => {
  const numInWordMap = {
    0: '零',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
    10: '十',
  };

  return numInWordMap[num];
};

export const getAudioTrackLabel = (idx) => {
  const numInWord = getSmallNumInChineseWord(idx + 1) ?? `${idx + 1}`;

  return `語言${numInWord}`;
};
