export const GA4CustomEvent = {
  // Player
  PLAYER_LOAD_START: "player_load_start",
  PLAYER_LOAD_COMPLETE: "player_load_complete",
  PLAYER_AD_REQUEST: "ad_request",
  PLAYER_AD_PLAY: "ad_play",
  PLAYER_VIDEO_PLAY: "video_play",
  PLAYER_VIDEO_PAUSE: "video_pause",
  PLAYER_VIDEO_BUFFER: "video_buffer",
  PLAYER_VIDEO_TERMINATE: "video_terminate",
  PLAYER_VIDEO_COMPLETE: "video_complete",
  PLAYER_VIDEO_ERROR: "video_error",
  PLAYER_REWIND_VIDEO: "rewind_video",
  PLAYER_FAST_FORWARD_VIDEO: "fast_forward_video",

  CLICK_VIDEO_HELP: "click_video_help",
  CLICK_PLAYER_PLAY: "click_player_play",
  CLICK_PLAYER_PAUSE: "click_player_pause",
  CLICK_PLAYER_RESUME: "click_player_resume",
  CLICK_PLAYER_NEXT: "click_player_next",
  CLICK_PLAYER_START_OVER: "click_player_start_over",
  CHANGE_AUDIO: "change_audio",
  CHANGE_PLAYBACK_SPEED: "change_playback_speed",
  CHANGE_SCREEN_MODE: "change_screen_mode",
  CHANGE_SUBTITLE: "change_subtitle",
  CHANGE_VOLUME: "change_volume",
  VIDEO_SEEK: "video_seek",
};
