import React, { useEffect } from "react";
import VideoModule from "../Components/VideoModule";
import { useCountDownContext } from "../Contexts/CountDownContext";

import loadingIcon from "../assets/images/loading.png"; // Import the loading SVG icon

const CountDownPageContents = () => {
  const { content, setContent, videoData, setVideoData } =
    useCountDownContext();

  const fetchData = async (url, fallbackUrl) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      if (Object.keys(data).length === 0) throw new Error("Empty data");
      return data;
    } catch (error) {
      console.error(`Failed to fetch from ${url}, using fallback: ${error}`);
      const fallbackResponse = await fetch(fallbackUrl);
      return fallbackResponse.json();
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        // Fetch metadata
        const data = await fetchData(
          "https://olympics-minisite-2024-dev.viu.tv/json/stage1.json"
          // `${process.env.PUBLIC_URL}/api/mock_stage1.json`
        );
        setContent(data);

        // Fetch video data for each module in parallel
        const videoPromises = data.videoList.map((module) =>
          fetchData(
            `https://olympics-minisite-2024-dev.viu.tv/v1/vods?tagAnd=${module.tagAnd}`
            // `${process.env.PUBLIC_URL}/api/mock_vods.json`
          ).then((videoData) => ({ [module.tagAnd]: videoData }))
        );

        const allVideoData = await Promise.all(videoPromises);
        const mergedVideoData = Object.assign({}, ...allVideoData);
        setVideoData(mergedVideoData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    if (!content) {
      fetchAllData();
    }
  }, [content, setContent, setVideoData]);

  return (
    <div className="dynamic-contents">
      {content ? (
        <div className="Rows">
          {content.videoList.map((module, index) =>
            videoData[module.tagAnd] ? (
              <div className="row-container" key={index}>
                <VideoModule
                  key={index}
                  moduleData={{ ...module, videos: videoData[module.tagAnd] }}
                />
              </div>
            ) : (
              <div className="loading-container">
                {/* <img src={loadingIcon} alt="Loading" className="loading-icon" /> */}
              </div>
            )
          )}
        </div>
      ) : (
        <div className="loading-container">
          <img src={loadingIcon} alt="Loading" className="loading-icon" />
        </div>
      )}
    </div>
  );
};

export default CountDownPageContents;
