// use by mobile menu and header menu
export const NavConfig = [
  {
    to: "/",
    label: "主頁",
  },
  // {
  //   to: "/live",
  //   label: "直播",
  // },
  // {
  //   to: "/encore",
  //   label: "賽事重溫",
  // },
  // {
  //   to: "/sports",
  //   label: "運動項目",
  // },
  // {
  //   to: "/hkteam",
  //   label: "中國香港隊專區",
  // },
  // {
  //   to: "/chinateam",
  //   label: "中國隊專區",
  // },
  {
    to: "/schedule",
    label: "賽程",
  },
  // {
  //   to: "/medal-table",
  //   label: "獎牌榜",
  // },
];
