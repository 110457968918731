import React, { createContext, useState, useEffect } from "react";
import getFormattedDate from "../Utils/Helpers";

export const ScheduleContext = createContext();

export const ScheduleProvider = ({ children }) => {
  const [scheduleData, setScheduleData] = useState([]);
  const [items, setItems] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("20240724");
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedItem, setSelectedItem] = useState("所有項目");
  const [channelList, setChannelList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("所有隊伍");
  const [loading, setLoading] = useState(false); // New loading state

  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      if (Object.keys(data).length === 0) throw new Error("Empty data");
      return data;
    } catch (error) {
      console.error(`Failed to fetch from ${url}: ${error}`);
      throw error;
    }
  };

  const formatChannelId = (id) => {
    return id.toString().padStart(3, '0');
  };

  const getChannelIds = () => {
    return selectedChannels.length > 0
      ? selectedChannels
      : [...new Set(channelList.map(({ channelId }) => formatChannelId(channelId)))];
  };

  const generateDateRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);
    while (currentDate <= endDate) {
      const dateStr = currentDate.toISOString().slice(0, 10).replace(/-/g, "");
      dates.push({
        date: dateStr,
        ...getFormattedDate(dateStr),
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const newChannels = await fetchData(
          `https://olympics-minisite-2024-dev.viu.tv/json/channels.json`
        );
        setChannelList(newChannels);
      } catch (error) {
        console.error("Failed to load channel data:", error);
      }
    };

    loadData();
    setDates(generateDateRange("2024-07-24", "2024-08-11"));
  }, []);

  const fetchScheduleAndTags = async (date) => {
    const channelIds = getChannelIds().join(",");
    if (!channelIds) return;

    setLoading(true); // Set loading to true before fetch
    
    try {
      const newScheduleData = await fetchData(
        `https://olympics-minisite-2024-dev.viu.tv/v1/epgs?channelId=${channelIds}&startAt=${date}&endAt=${Number(date) + 1}`
      );
      const tagsData = await fetchData(
        `https://olympics-minisite-2024-dev.viu.tv/json/tags.json`
      );

      setScheduleData(newScheduleData);
      setItems([
        "所有項目",
        ...tagsData.filter((tag) => tag.type === "sport").map((tag) => tag.ch),
      ]);
    } catch (error) {
      console.error("Failed to load schedule or tags data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetch
    }
  };

  useEffect(() => {
    if (channelList.length > 0 && selectedDate) {
      fetchScheduleAndTags(selectedDate);
    }
  }, [channelList, selectedDate]);

  const handleDateSelect = async (date) => {
    setSelectedDate(date);
    await fetchScheduleAndTags(date);
  };

  const updateChannelsAndFetchData = async (channels) => {
    setSelectedChannels(channels);
    if (selectedDate) {
      await fetchScheduleAndTags(selectedDate);
    }
  };

  return (
    <ScheduleContext.Provider
      value={{
        scheduleData,
        setScheduleData,
        items,
        setItems,
        dates,
        setDates,
        selectedDate,
        setSelectedDate,
        selectedChannels,
        setSelectedChannels,
        selectedItem,
        setSelectedItem,
        selectedTeam,
        setSelectedTeam,
        handleDateSelect,
        updateChannelsAndFetchData, // Add updateChannelsAndFetchData to the context
        channelList, // Add channelList to the context
        loading // Add loading to the context
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};
