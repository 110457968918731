// src/App.js
import React from "react";

import "../../index.css";

import { NavConfig } from "../../Utils/NavConfig";
import { NavLink } from "react-router-dom";

function MobileMenuPage() {
  return (
    <div className="MobileMenuPage">
      {NavConfig.map((item) => (
        <NavLink
          key={item.to}
          to={item.to}
          className="Header-link mobile-menu-link"
          activeclassname="active"
        >
          {item.label}
        </NavLink>
      ))}
    </div>
  );
}

export default MobileMenuPage;
