// src/components/Header.js
import React, { useContext } from "react";
import { KeyVisualContext } from "../Contexts/KeyVisualContext";
import orangeRectangle from "../assets/images/orange-rectangle.svg"; // Import the SVG

const TitleBar = () => {
  const { adImage } = useContext(KeyVisualContext);

  return (
    <div className="TitleBar">
      {adImage && (
        <div className="Advertisement">
          <img className="adv-img" src={adImage} alt="Advertisement" />
        </div>
      )}
      <div className="TitleBar-container container">
        <img
          src={orangeRectangle}
          alt="orange rectangle"
          className="orange-rectangle"
        />
        <div className="TitleBar-title">賽程</div>
      </div>
    </div>
  );
};

export default TitleBar;
