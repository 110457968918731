import React, { useState } from "react";
import VideoCard from "./VideoCard";
import orangeRectangle from "../assets/images/orange-rectangle.svg"; // Import the SVG

const VideoModule = ({ moduleData }) => {
  const [showMore, setShowMore] = useState(false);

  const featuredVideos = moduleData.videos.slice(0, 1); // Make the first video as featured video
  const otherVideos = moduleData.videos.slice(1);

  const firstRowVideosCount = 4;
  const firstRowVideos = otherVideos.slice(0, firstRowVideosCount);
  const remainingVideos = otherVideos.slice(firstRowVideosCount);

  const shouldShowButton = otherVideos.length > firstRowVideosCount;

  const handleShowMore = () => setShowMore(true);
  return (
    <div className="container section-container">
      <div className="section-title-container">
        <img
          src={orangeRectangle}
          alt="orange rectangle"
          className="orange-rectangle"
        />
        <h2 className="section-title">{moduleData.header}</h2>
      </div>

      <div className="video-sections-container">
        {featuredVideos.length > 0 && (
          <div className="row">
            <div
              className="col col-12 col-md-6 mb-3 featured"
              style={{
                paddingLeft: "20px",
                paddingRight: "10px",
                paddingBottom: "20px",
              }}
            >
              {featuredVideos.map((videoBundle, index) => (
                <VideoCard key={index} {...videoBundle} featured />
              ))}
            </div>
            <div
              className="col col-12 col-md-6 row"
              style={{ paddingRight: "0px" }}
            >
              {firstRowVideos.map((videoBundle, index) => (
                <div
                  className="col col-12 col-md-6 mb-3"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "15px",
                  }}
                  key={index}
                >
                  <VideoCard {...videoBundle} />
                </div>
              ))}
            </div>
          </div>
        )}

        {showMore && (
          <div className="row">
            <div
              className="col col-12 col-md-6 row"
              style={{ paddingRight: "0px", paddingLeft: "25px" }}
            >
              {remainingVideos.map((videoBundle, index) => (
                <div
                  className="col col-12 col-md-6 mb-3"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "15px",
                  }}
                  key={index}
                >
                  <VideoCard {...videoBundle} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {shouldShowButton && !showMore && (
        <div className="row justify-content-center">
          <div className="show-more-btn-container">
            <div className="button btn-show-more" onClick={handleShowMore}>
              顯示更多
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoModule;
