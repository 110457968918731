// src/contexts/CountDownContext.js
import React, { createContext, useState, useContext } from 'react';

const CountDownContext = createContext();

export const CountDownProvider = ({ children }) => {
  const [content, setContent] = useState(null);
  const [videoData, setVideoData] = useState({});

  return (
    <CountDownContext.Provider value={{ content, setContent, videoData, setVideoData }}>
      {children}
    </CountDownContext.Provider>
  );
};

export const useCountDownContext = () => useContext(CountDownContext);
