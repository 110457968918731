import React, { useRef, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import "../index.css";

import leftArrowIcon from "../assets/icons/arrow_l.svg";
import rightArrowIcon from "../assets/icons/arrow_r.svg";

const HorizontalDatePicker = ({ dates, onDateSelect, selectedDate }) => {
  const datePickerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const checkBoundaries = useCallback(() => {
    if (datePickerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = datePickerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 1);
    }
  }, []);

  useEffect(() => {
    checkBoundaries();
    window.addEventListener("resize", checkBoundaries);
    return () => window.removeEventListener("resize", checkBoundaries);
  }, [checkBoundaries]);

  useEffect(() => {
    checkBoundaries(); // Ensure arrows are shown initially
  }, [dates]); // Run this effect after dates have been set

  const scroll = (direction) => {
    if (datePickerRef.current) {
      const scrollAmount = datePickerRef.current.clientWidth / 2;
      datePickerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    checkBoundaries();
  };

  return (
    <div className="horizontal-date-picker-container">
      {showLeftArrow && (
        <button className="scroll-button left" onClick={() => scroll("left")}>
          <img src={leftArrowIcon} alt="Scroll Left" className="scroll-icon" />
        </button>
      )}
      <div
        className="horizontal-date-picker"
        ref={datePickerRef}
        onScroll={handleScroll}
      >
        {dates.map((date) => (
          <div
            key={date.date}
            className={`date-item ${
              selectedDate === date.date ? "selected" : ""
            }`}
            onClick={() => onDateSelect(date.date)}
          >
            <div className="month-day">{date.monthDay}</div>
            <div className="week-day">{date.weekDay}</div>
          </div>
        ))}
      </div>
      {showRightArrow && (
        <button className="scroll-button right" onClick={() => scroll("right")}>
          <img src={rightArrowIcon} alt="Scroll Right" className="scroll-icon" />
        </button>
      )}
    </div>
  );
};

HorizontalDatePicker.propTypes = {
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      monthDay: PropTypes.string.isRequired,
      weekDay: PropTypes.string.isRequired,
    })
  ).isRequired,
  onDateSelect: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
};

export default HorizontalDatePicker;
