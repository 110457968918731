// src/App.js
import React from "react";
import KeyVisual from "../Sections/KeyVisual";

import "../index.css";
import CountDownPageContents from "../Sections/CountDownPageContents";

function CountDownPage() {
  return (
    <div className="CountDownPage">
      <KeyVisual />
      <CountDownPageContents />
    </div>
  );
}

export default CountDownPage;
