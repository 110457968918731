import { BREAKPOINT } from '../Constants/screen';

export const cmsImageSizesAvailable = [
  176, 320, 512, 640, 800, 1024, 1280,
];

export const STATIC_IMAGE_DOMAINS = [
  'static.viu.tv',
  'viutv-cms.s3.amazonaws.com',
];

const defaultAdaptiveImageSizes = [
  `(max-width: ${BREAKPOINT.SM_MIN}px) 640px`,
  `(max-width: ${BREAKPOINT.MD_MIN}px) 800px`,
  `(max-width: ${BREAKPOINT.LG_MIN}px) 1024px`,
  `1280px`,
];

export const getAdaptiveImageData = (src) => {
  try {
    if (!src) {
      throw new Error(`adaptive-image:: image path not existing: ${src}`);
    }

    const parsed = new URL(src);

    if (!/(\.jpg|\.jpeg|\.png)$/i.test(parsed.pathname)) {
      throw new Error(`adaptive-image:: not support image type: ${src}`);
    }

    if (!STATIC_IMAGE_DOMAINS.includes(parsed.hostname)) {
      throw new Error(
        `adaptive-image:: image is not static CDN domain: ${src}`
      );
    }

    const imagesUrls = cmsImageSizesAvailable.map((width) =>
      `${parsed.origin}/resized-${width}${parsed.pathname}${parsed.search}`
        .replace('.JPG', '.jpg')
        .replace('.JPEG', '.jpeg')
        .replace('.PNG', '.png')
    );

    const sizesMap = Object.fromEntries(
      cmsImageSizesAvailable.map((width, idx) => [width, imagesUrls[idx]])
    );

    const srcsetArray = cmsImageSizesAvailable.map(
      (width, idx) => `${imagesUrls[idx]} ${width}w`
    );

    const srcset = srcsetArray.join(',');

    return {
      src,
      srcset,
      sizesMap,
      defaultSizes: defaultAdaptiveImageSizes.join(','),
    };
  } catch {
    return { src };
  }
};
