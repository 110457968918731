import debounce from 'lodash.debounce';
import { useRef, useState } from 'react';

import useVideoTracking from '../../../Hooks/useVideoTracking';

import tracker from '../../../Utils/tracking/ga4/tracker';
import { getAudioTrackLabel } from '../../../Utils/video';
import VodPlayer, { IS_AUTOPLAY } from '../VodPlayer';
import { useVideoContext } from '../../../Contexts/VideoContext';
import { SCREEN_MODE } from '../../../Utils/tracking/ga4/event/vod_player';


const DataTrackingVodPlayer = () => {
  const isPlayLikelyCausedByInteractionRef = useRef(false);
  const currentScreenModeRef = useRef(SCREEN_MODE.EMBEDDED);

  const [isClickedPlay, setIsClickedPlay] = useState(false);

  const [isPlayerDisplayClicked, setIsPlayerDisplayClicked] = useState(false);

  const { vodItem, videoBundle } = useVideoContext();

  const playerRef = useRef(null);

  const programme = videoBundle?.programme;

  const { getCommonTrackingPayload, onTime } = useVideoTracking({
    playerRef,
    programme,
  });

  return (
    <VodPlayer
      ref={playerRef}
      key={vodItem?.vodMeta?.productId}
      onInit={(playerProxy) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        playerProxy.once('displayClick', () => {
          setIsPlayerDisplayClicked(true);
        });
        const commonTrackingPayload = getCommonTrackingPayload();
        if (commonTrackingPayload) {
          const trackingPayload = {
            video_info: commonTrackingPayload.video_info,
            video_info_en: commonTrackingPayload.video_info_en,
            programme_autoplay: IS_AUTOPLAY,
          };
          tracker.vodPlayer.playerLoadStart(trackingPayload);
        }
      }}
      onReady={() => {
        const trackingPayload = getCommonTrackingPayload();
        if (trackingPayload) {
          tracker.vodPlayer.playerLoadComplete({
            video_info: trackingPayload.video_info,
            video_info_en: trackingPayload.video_info_en,
          });
        }
      }}
      onAdRequest={(evt) => {
        const commonTrackingPayload = getCommonTrackingPayload();
        if (commonTrackingPayload) {
          const trackingPayload = {
            ...commonTrackingPayload,
            ad_req: evt.adposition,
          };
          tracker.vodPlayer.playerAdRequest(trackingPayload);
        }
      }}
      onAdPlay={(evt, { canAutoplay }) => {
        const commonTrackingPayload = getCommonTrackingPayload();
        if (commonTrackingPayload) {
          const programme_autoplay =
            IS_AUTOPLAY && !!canAutoplay && canAutoplay !== 'autoplayDisabled';

          const sequence = evt.sequence ?? 1;

          const trackingPayload = {
            ...commonTrackingPayload,
            ad_pos: `${evt.adposition} ${sequence}`,
            sequence,
            programme_autoplay,
          };

          tracker.vodPlayer.playerAdPlay(trackingPayload);
        }
      }}
      onFirstFrame={(_, payload) => {
        const { canAutoplay } = payload ?? {};
        const commonTrackingPayload = getCommonTrackingPayload();

        if (commonTrackingPayload) {
          const trackingPayload = {
            ...commonTrackingPayload,
            programme_autoplay:
              IS_AUTOPLAY &&
              !!canAutoplay &&
              canAutoplay !== 'autoplayDisabled',
          };

          tracker.vodPlayer.playerVideoPlay(trackingPayload);
        }
      }}
      onPause={(evt) => {
        const trackingPayload = getCommonTrackingPayload();
        if (trackingPayload) {
          tracker.vodPlayer.playerVideoPause(trackingPayload);
          if (evt.pauseReason === 'interaction') {
            tracker.vodPlayer.clickPause(trackingPayload);
            setIsClickedPlay(true);
          }
        }
      }}
      onBuffer={() => {
        const trackingPayload = getCommonTrackingPayload();
        if (trackingPayload) {
          tracker.vodPlayer.playerVideoBuffer(trackingPayload);
        }
      }}
      onComplete={() => {
        // set to true so that when use click to replay again will succesfully invoke tracker.vodPlayer.clickPlay()
        isPlayLikelyCausedByInteractionRef.current = true;

        const trackingPayload = getCommonTrackingPayload();
        if (trackingPayload) {
          tracker.vodPlayer.playerVideoComplete(trackingPayload);
        }
      }}
      onRemove={() => {
        setIsPlayerDisplayClicked(false);
        const commonTrackingPayload = getCommonTrackingPayload();
        if (commonTrackingPayload) {
          tracker.vodPlayer.playerVideoTerminate(commonTrackingPayload);
        }
      }}
      onSetupError={(evt) => {
        const commonTrackingPayload = getCommonTrackingPayload();

        if (commonTrackingPayload) {
          const trackingPayload = {
            ...commonTrackingPayload,
            error_code: evt.code,
            error_message: evt.message,
          };

          tracker.vodPlayer.playerVideoError(trackingPayload);
        }
      }}
      onError={(evt) => {
        console.error(
          new Error(
            JSON.stringify({
              currentVideoKey: vodItem?.vodMeta?.productId,
              userAgent: navigator?.userAgent,
              errorCode: evt.code,
              errorMessage: evt.message,
            })
          ),
          'video::error:: onError called on encore player'
        );
        const commonTrackingPayload = getCommonTrackingPayload();

        if (commonTrackingPayload) {
          const trackingPayload = {
            ...commonTrackingPayload,
            error_code: evt.code,
            error_message: evt.message,
          };

          tracker.vodPlayer.playerVideoError(trackingPayload);
        }
      }}
      onUserAudioTrackChanged={(evt) => {
        const commonTrackingPayload = getCommonTrackingPayload();

        if (commonTrackingPayload) {
          const trackingPayload = {
            ...commonTrackingPayload,
            audio_channel: getAudioTrackLabel(evt.currentTrack),
          };

          tracker.vodPlayer.changeAudio(trackingPayload);
        }
      }}
      onUserPlaybackRates={(evt) => {
        const commonTrackingPayload = getCommonTrackingPayload();

        if (commonTrackingPayload) {
          const trackingPayload = {
            ...commonTrackingPayload,
            playback_speed: evt.playbackRate,
          };

          tracker.vodPlayer.changePlaybackSpeed(trackingPayload);
        }
      }}
      onFullscreen={(evt) => {
        const commonTrackingPayload = getCommonTrackingPayload();
        if (commonTrackingPayload) {
          const newScreenMode = evt.fullscreen
            ? SCREEN_MODE.FULLSCREEN
            : SCREEN_MODE.EMBEDDED;

          const trackingPayload = {
            ...commonTrackingPayload,
            screen_mode_from: currentScreenModeRef.current,
            screen_mode: newScreenMode,
          };

          currentScreenModeRef.current = newScreenMode;

          tracker.vodPlayer.changeScreenMode(trackingPayload);
        }
      }}
      onPip={(evt) => {
        const commonTrackingPayload = getCommonTrackingPayload();
        if (commonTrackingPayload) {
          const newScreenMode = evt.pip
            ? SCREEN_MODE.PIP
            : SCREEN_MODE.EMBEDDED;

          const trackingPayload = {
            ...commonTrackingPayload,
            screen_mode_from: currentScreenModeRef.current,
            screen_mode: newScreenMode,
          };

          currentScreenModeRef.current = newScreenMode;

          tracker.vodPlayer.changeScreenMode(trackingPayload);
        }
      }}
      onUserVolumeChanged={debounce((evt) => {
        const commonTrackingPayload = getCommonTrackingPayload();

        if (commonTrackingPayload) {
          tracker.vodPlayer.changeVolume({
            ...commonTrackingPayload,
            volume_status: evt.volumeStatus,
          });
        }
      }, 300)}
      onUserCaptionsChanged={(_, { captionLabel }) => {
        const commonTrackingPayload = getCommonTrackingPayload();

        if (commonTrackingPayload) {
          const trackingPayload = {
            ...commonTrackingPayload,
            subtitle: captionLabel.zh,
          };

          tracker.vodPlayer.changeSubtitle(trackingPayload);
        }
      }}
      onRewind={() => {
        const commonTrackingPayload = getCommonTrackingPayload();

        if (commonTrackingPayload) {
          tracker.vodPlayer.playerRewind(commonTrackingPayload);
        }
      }}
      onFastForward={() => {
        const commonTrackingPayload = getCommonTrackingPayload();

        if (commonTrackingPayload) {
          tracker.vodPlayer.playerFastFoward(commonTrackingPayload);
        }
      }}
      onClickNext={() => {
        const trackingPayload = getCommonTrackingPayload();
        if (trackingPayload) {
          tracker.vodPlayer.clickNext(trackingPayload);
        }
      }}
      onPlay={(evt) => {
        if (
          isPlayerDisplayClicked ||
          evt.playReason === 'interaction' ||
          isPlayLikelyCausedByInteractionRef.current
        ) {
          if (isPlayerDisplayClicked) {
            setIsPlayerDisplayClicked(false);
          }

          isPlayLikelyCausedByInteractionRef.current = false;
          const trackingPayload = getCommonTrackingPayload();
          if (trackingPayload) {
            if (isClickedPlay) {
              tracker.vodPlayer.clickResume(trackingPayload);
            } else {
              tracker.vodPlayer.clickPlay(trackingPayload);
              setIsClickedPlay(true);
            }
          }
        }

        if (evt.playReason === 'autostart' && !isClickedPlay) {
          setIsClickedPlay(true);
        }
      }}
      onSeek={debounce((evt, { seekReason }) => {
        if (seekReason !== 'initialStart') {
          const trackingPayload = getCommonTrackingPayload({
            time: evt.offset,
          });
          if (trackingPayload) {
            tracker.vodPlayer.seek(trackingPayload);
          }
        }
      }, 300)}
      onTime={onTime} // required for updating player's last timestamp as tracking payload fallback
      // youboraId={process.env.YOUBORA_ID}
    />
  );
};

export default DataTrackingVodPlayer;
