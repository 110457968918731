import React from "react";
import logo from "../assets/images/viutv_logo.png";
import desc from "../assets/images/desc.svg";

import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { NavConfig } from "../Utils/NavConfig";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isMobileView, setIsMobileView] = React.useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    if (isMenuOpen) {
      navigate(-1);
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const handleResize = () => {
    setIsMobileView(window.matchMedia("(max-width: 1024px)").matches);
  };

  React.useEffect(() => {
    if (location.pathname !== "/mobile-menu") {
      setIsMenuOpen(false);
    }
    if (location.pathname === "/mobile-menu") {
      setIsMenuOpen(isMobileView);
      if (!isMobileView) {
        navigate(-1);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [location, isMobileView, navigate]);

  return (
    <div className="Header">
      <div className="logo-container" onClick={() => navigate("/")}>
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="Header-menu">
        {!isMobileView &&
          NavConfig.map((item) => (
            <NavLink
              key={item.to}
              to={item.to}
              className="Header-link"
              activeclassname="active"
            >
              {item.label}
            </NavLink>
          ))}
        {isMobileView && (
          <>
            {isMenuOpen ? (
              <div className="hamburger-menu-close" onClick={toggleMenu}></div>
            ) : (
              <div className="hamburger-menu" onClick={toggleMenu}>
                <Link
                  to="/mobile-menu"
                  className="Header-link"
                  activeclassname="active"
                ></Link>
              </div>
            )}
          </>
        )}
      </div>
      {!isMobileView && (
        <div className="rectangle-container">
          <div className="rectangle rectangle-1"></div>
          <div className="rectangle rectangle-2"></div>
          <div className="rectangle rectangle-3"></div>
          <div className="rectangle rectangle-4"></div>
          <div className="rectangle rectangle-5"></div>
        </div>
      )}
    </div>
  );
};

export default Header;
