import React from "react";
import PropTypes from "prop-types";
import backIcon from "../assets/icons/back.svg";
import closeIcon from "../assets/icons/close.svg";
import tickIcon from "../assets/icons/tick.svg";

const FilterList = ({
  show,
  title,
  items,
  selectedItem,
  onSelect,
  onBack,
  onClose,
}) => {
  return (
    <div className={`filter-list-overlay ${show ? "show" : ""}`}>
      <div className="filter-list-header">
        <img src={backIcon} alt="Back" onClick={onBack} />
        <span>{title}</span>
        <img src={closeIcon} alt="Close" onClick={onClose} />
      </div>
      <div className="filter-list-content">
        {items.map((item) => (
          <div
            key={item}
            className={`filter-list-item ${
              selectedItem === item ? "selected" : ""
            }`}
            onClick={() => onSelect(item)}
          >
            <span>{item}</span>
            <div className="filter-list-item-icons">
              {selectedItem === item && <img src={tickIcon} alt="Tick" />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

FilterList.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItem: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FilterList;
