import * as VodPlayerTrackFunc from './event/vod_player';

const _vodPlayerFunc = {
  playerLoadStart: VodPlayerTrackFunc.trackPlayerLoadStart,
  playerLoadComplete: VodPlayerTrackFunc.trackPlayerLoadComplete,
  playerAdRequest: VodPlayerTrackFunc.trackPlayerAdRequest,
  playerAdPlay: VodPlayerTrackFunc.trackPlayerAdPlay,
  playerVideoPlay: VodPlayerTrackFunc.trackPlayerVideoPlay,
  playerVideoPause: VodPlayerTrackFunc.trackPlayerVideoPause,
  playerVideoBuffer: VodPlayerTrackFunc.trackPlayerVideoBuffer,
  playerVideoComplete: VodPlayerTrackFunc.trackPlayerVideoComplete,
  playerVideoTerminate: VodPlayerTrackFunc.trackPlayerVideoTerminate,
  playerVideoError: VodPlayerTrackFunc.trackPlayerVideoError,
  changeAudio: VodPlayerTrackFunc.trackChangeAudio,
  changePlaybackSpeed: VodPlayerTrackFunc.trackChangePlaybackSpeed,
  changeScreenMode: VodPlayerTrackFunc.trackChangeScreenMode,
  changeVolume: VodPlayerTrackFunc.trackChangeVolume,
  changeSubtitle: VodPlayerTrackFunc.trackChangeSubtitle,
  playerRewind: VodPlayerTrackFunc.trackPlayerRewind,
  playerFastFoward: VodPlayerTrackFunc.trackPlayerFastForward,
  clickNext: VodPlayerTrackFunc.trackClickPlayerNext,
  clickPause: VodPlayerTrackFunc.trackClickPlayerPause,
  clickPlay: VodPlayerTrackFunc.trackClickPlayerPlay,
  clickResume: VodPlayerTrackFunc.trackClickPlayerResume,
  seek: VodPlayerTrackFunc.trackVideoSeek,
  // clickMoreVideos: VodPlayerTrackFunc.trackClickPlayerShowEpisodeList, // no need
  // clickHelp: VodPlayerTrackFunc.trackClickVideoHelp, // no need

  SCREEN_MODE: VodPlayerTrackFunc.SCREEN_MODE,
  PLAYER_VOLUME: VodPlayerTrackFunc.PLAYER_VOLUME,
};

const tracker = {
  vodPlayer: _vodPlayerFunc,
};

export default tracker;
