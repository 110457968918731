import {
  ViuTvWebVideoPlayer,
  playerManager,
} from "@viutv-digital/viutv-web-video-player";
import { forwardRef } from "react";
import { useVideoContext } from "../../../Contexts/VideoContext";

playerManager.setConfig({
  fairplayCertURL: process.env.REACT_APP_FAIRPLAY_CERT_URL,
  nowApiBaseUrl: process.env.REACT_APP_NOW_API_BASE_URL,
  nowApiPin: process.env.REACT_APP_NOW_API_PIN,
  nowFwpBaseUrl: process.env.REACT_APP_NOW_FWP_BASE_URL,
});

export const IS_AUTOPLAY = true;

const VodPlayer = forwardRef((props, ref) => {
  const { vodItem } = useVideoContext();

  if (!vodItem?.vodMeta) {
    console.error("Error: No vodMeta found");
    return null;
  }

  return (
    <ViuTvWebVideoPlayer
      ref={ref}
      key={vodItem.vodMeta.productId}
      mode="vod"
      vodMeta={vodItem.vodMeta}
      videoCoverInfo={vodItem.videoCoverInfo ?? null}
      reactLocale="zh"
      autostart={IS_AUTOPLAY}
      autoPause={{
        pauseAds: false,
        viewability: false,
      }}
      advertising={{
        locale: "zh_hk",
      }}
      // getVodUrl={import.meta.env.VITE_GET_VOD_URL} // prod player not support yet
      {...props}
    />
  );
});
VodPlayer.displayName = "VodPlayer";

export default VodPlayer;
