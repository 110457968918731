import React from "react";
import liveIcon from "../assets/icons/live.svg";
import ExternalLinkIcon from "../assets/icons/external_link.svg";

import loadingIcon from "../assets/images/loading.png"; // Import the loading SVG icon


const ScheduleList = ({ data = [], channelList = [], loading }) => {
  if (loading) {
    return (
      <div className="loading-container">
        <img src={loadingIcon} alt="Loading" className="loading-icon" />
      </div>
    );
  }

  if (!data || !data.length) {
    return <div>未有賽事</div>;
  }

  const isActive = (item, index) => index === 0;

  const handleExternalLinkClick = (channelId) => {
    const channel = channelList.find((ch) => ch.channelId == channelId);
    if (channel && channel.slug) {
      window.open(`https://viu.tv/ch/${channel.slug}`, "_blank");
    }
  };

  const formatChannelId = (id) => {
    return id.toString().padStart(3, "0");
  };

  const groupedData = data.reduce((acc, item) => {
    acc[item.startTime] = acc[item.startTime] || [];
    acc[item.startTime].push(item);
    return acc;
  }, {});

  return (
    <div className="schedule-list">
      {Object.keys(groupedData).map((time, groupIndex) => (
        <div key={groupIndex} className="schedule-group">
          <div className="schedule-group-header">
            <div className="schedule-time">{time}</div>
            <div className="schedule-group-column">
              {groupedData[time].map((item, index) => (
                <div
                  key={index}
                  className={`schedule-item ${
                    isActive(item, index) ? "active" : ""
                  }`}
                >
                  <div className="schedule-content">
                    <div className="schedule-channel">
                      <span>CH.{formatChannelId(item.channelId)}</span>
                    </div>
                    <div className="flex-vertical-container">
                      <div className="schedule-description">
                        {item.description}
                      </div>
                      <div
                        className={`schedule-teams ${
                          isActive(item, index) ? "active" : ""
                        }`}
                      >
                        {item.teams.join(" vs ")}
                      </div>
                    </div>
                  </div>
                  <div className="schedule-right-div">
                    {isActive(item, index) && (
                      <div className="live-streaming">
                        <img
                          src={liveIcon}
                          alt="Live Icon"
                          className="live-icon"
                        />
                        <span>直播中</span>
                      </div>
                    )}
                    {isActive(item, index) && (
                      <div
                        className="live-indicator"
                        onClick={() => handleExternalLinkClick(item.channelId)}
                      >
                        <span>前往直播</span>
                        <img
                          src={ExternalLinkIcon}
                          alt="External Link Icon"
                          className="external-link-icon"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {groupIndex !== Object.keys(groupedData).length - 1 && (
            <hr className="schedule-divider" />
          )}
        </div>
      ))}
    </div>
  );
};

export default ScheduleList;
