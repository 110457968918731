import React, { useContext } from "react";
import { KeyVisualContext } from "../Contexts/KeyVisualContext";

const KeyVisual = () => {
  const { keyVisualImage, adImage } = useContext(KeyVisualContext);

  return (
    <div
      className="KeyVisual"
      style={{
        backgroundImage: `url(${keyVisualImage})`,
      }}
    >
      {adImage && (
        <div className="Advertisement">
          <img className="adv-img" src={adImage} alt="Advertisement" />
        </div>
      )}
    </div>
  );
};

export default KeyVisual;
