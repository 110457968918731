import Cookies from 'js-cookie';

import { debugGaCustomEventSent, getIsEnabledDebugFlags } from '../Utils/debug';
import { sendGAEvent as pushEventToGA4 } from '../Utils/tracking/ga4';

let isEnableDebugTracking = false;

if (typeof window !== 'undefined') {
  isEnableDebugTracking = getIsEnabledDebugFlags('tracking') || false;
}

export const sendGACustomEvent = ({ payload }) => {
  const { event_name, ...params } = payload;

  const eventParams = {
    ..._getUniversalParams(),
    ...params,
    page_title: params.page_title,
  };

  pushEventToGA4(event_name, eventParams);

  if (isEnableDebugTracking) {
    debugGaCustomEventSent(event_name, eventParams);
  }
};

const _getUniversalParams = () => {
  const _uid = Cookies.get('uid');

  return {
    has_login_token: _uid ? 'True' : 'False',
    ...(_uid && { user_id: _uid }),
    platform: 'web',
  };
};
