// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./Sections/Header";
import { RoutesConfig } from "./Utils/RoutesConfig";
import Footer from "./Sections/Footer";
import { CountDownProvider } from "./Contexts/CountDownContext"; // Assuming you have this context
import { ScheduleProvider } from "./Contexts/ScheduleContext";
import { KeyVisualProvider } from "./Contexts/KeyVisualContext";
import { VideoProvider } from "./Contexts/VideoContext";

import "./bootstrap.css";
import "./index.css";
import "./styles/player.scss";
import OverlayVideoPlayer from "./Components/OverlayVideoPlayer";

const App = () => {
  const location = useLocation();
  const showHeaderFooter = location.pathname !== "/video-player";

  return (
    <>
      <div>
        {showHeaderFooter && <Header />}
        <main id="page-wrap" className="Page Page-Event">
          <div className="Content">
            <Routes>
              {RoutesConfig.map((route) => (
                <Route key={route.path} {...route} />
              ))}
            </Routes>
          </div>
        </main>
        {showHeaderFooter && <Footer />}
        
      </div>
      <OverlayVideoPlayer />
    </>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <VideoProvider>
        <CountDownProvider>
          <ScheduleProvider>
            <KeyVisualProvider>
              <VideoProvider>
                <App />
              </VideoProvider>
            </KeyVisualProvider>
          </ScheduleProvider>
        </CountDownProvider>
      </VideoProvider>
    </Router>
  );
};

export default AppWrapper;
