import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getVideoCoverInfoFromEpisode,
  getVideoVodMetaFromEpisodeAndProgramme,
} from "../Utils/Vod";

const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [videoBundle, setVideoBundle] = useState(null);
  const [isShowOverlayPlayer, setIsShowOverlayPlayer] = useState(false);
  const [vodItem, setVodItem] = useState(null);

  useEffect(() => {
    if (!videoBundle || !videoBundle.video || !videoBundle.programme) {
      setIsShowOverlayPlayer(false);
      return;
    }
    setVodItem({
      vodMeta: getVideoVodMetaFromEpisodeAndProgramme({
        episode: videoBundle.video,
        programme: videoBundle.programme,
      }),
      videoCoverInfo: getVideoCoverInfoFromEpisode(videoBundle.video),
    });
    setIsShowOverlayPlayer(true);
  }, [videoBundle]);

  const closeOverlayPlayer = () => {
    setVideoBundle(null);
  };

  return (
    <VideoContext.Provider
      value={{
        videoBundle,
        setVideoBundle,
        isShowOverlayPlayer,
        setIsShowOverlayPlayer,
        vodItem,
        setVodItem,

        // helper functions
        closeOverlayPlayer,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export const useVideoContext = () => {
  const context = useContext(VideoContext);
  if (context === undefined) {
    throw new Error("useVideoContext must be used within a VideoProvider");
  }
  return context;
};
