const getFormattedDate = (dateString) => {
    const date = new Date(
      `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}`
    );
  
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const weekDays = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    const weekDay = weekDays[date.getDay()];
  
    const monthDay = `${month}月${day}日`;
  
    return { monthDay, weekDay };
  };
  
  export default getFormattedDate;