import React, { useState, useEffect, useContext } from "react";
import TitleBar from "../Components/TitleBar";
import HorizontalDatePicker from "../Components/HorizontalDatePicker";
import ScheduleList from "../Components/ScheduleList";
import CustomDropdown from "../Components/CustomDropdown";
import filterIcon from "../assets/icons/filter.svg";
import FilterOverlay from "../Components/FilterOverlay";
import { ScheduleContext } from "../Contexts/ScheduleContext";

const SchedulePage = () => {
  const {
    scheduleData,
    items,
    dates,
    selectedDate,
    selectedChannels,
    setSelectedChannels,
    selectedItem,
    setSelectedItem,
    selectedTeam,
    setSelectedTeam,
    handleDateSelect,
    setSelectedDate,
    updateChannelsAndFetchData, // Add updateChannelsAndFetchData here
    channelList, // Add channelList here
    loading // Add loading here
  } = useContext(ScheduleContext);
  
  const [activeFilter, setActiveFilter] = useState("所有賽事");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilters, setShowFilters] = useState(false); // State for showing filters
  const [filterButtonText, setFilterButtonText] = useState("99、96台精選賽事");

  const teamOptions = ["所有隊伍", "中國隊", "中國香港隊"];

  useEffect(() => {
    filterDataFunc(
      scheduleData,
      selectedDate,
      selectedChannels,
      selectedItem,
      selectedTeam
    );

    // Add event listener for window resize
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setFilterButtonText("99、96台");
      } else {
        setFilterButtonText("99、96台精選賽事");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call to set the correct text

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [
    scheduleData,
    dates,
    selectedDate,
    selectedChannels,
    selectedItem,
    selectedTeam,
  ]);

  useEffect(() => {
    if (showFilters) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showFilters]);

  const filterDataFunc = (data, date, channels, item, team) => {
    if (!data || !date) {
      console.error("Missing required parameters: data or date");
      return;
    }

    let filtered = data.filter((event) => event.date === date);

    if (channels && channels.length > 0) {
      filtered = filtered.filter((event) => channels.includes(event.channelId));
    }

    if (item && item !== "所有項目") {
      filtered = filtered.filter((event) => event.sports.includes(item));
    }

    if (team === "中國香港隊") {
      filtered = filtered.filter((event) => event.includeHK);
    } else if (team === "中國隊") {
      filtered = filtered.filter((event) => event.includeChina);
    } else if (team !== "所有隊伍") {
      filtered = filtered.filter((event) => event.teams.includes(team));
    }

    const today = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    if (date === today) {
      filtered = filtered.filter((event) => event.date >= today);
    }

    setFilteredData(filtered);
  };

  const handleFilterAll = async () => {
    setSelectedChannels([]);
    setActiveFilter("所有賽事");
    await updateChannelsAndFetchData([]);
  };

  const handleFilterSelectedChannels = async () => {
    const channels = ["099", "096"];
    setActiveFilter("99、96台精選賽事");
    await updateChannelsAndFetchData(channels);
  };

  const onClickFilterButton = (show) => {
    setShowFilters(show);
  };

  const removeTag = (tagType) => {
    if (tagType === "item") {
      setSelectedItem("所有項目");
    } else if (tagType === "team") {
      setSelectedTeam("所有隊伍");
    } else if (tagType === "date") {
      setSelectedDate(dates[0].date);
    }
  };

  return (
    <div className="SchedulePage Rows">
      <TitleBar title="賽程" />
      <div className="schedule-page-container container">
        <div className="schedule-filter">
          <div
            className="filter-button"
            onClick={() => onClickFilterButton(!showFilters)}
          >
            <img src={filterIcon} alt="Filter" />
            <span>篩選</span>
          </div>
          <div className="schedule-filter-items-btns left-append">
            <CustomDropdown
              title="所有項目"
              items={items}
              selectedItem={selectedItem}
              onSelect={setSelectedItem}
              onOpenDropDown={() => {}}
              maxDisplayItems={5} // Set maximum display items
            />
            <CustomDropdown
              title="所有隊伍"
              items={teamOptions}
              selectedItem={selectedTeam}
              onSelect={setSelectedTeam}
              onOpenDropDown={() => {}}
              maxDisplayItems={3} // Set maximum display items for teams
            />
          </div>
          <div className="toggle-buttons right-append">
            <div
              className={`toggle-button ${
                activeFilter === "99、96台精選賽事" ? "active" : ""
              }`}
              onClick={handleFilterSelectedChannels}
            >
              {filterButtonText}
            </div>
            <div
              className={`toggle-button ${
                activeFilter === "所有賽事" ? "active" : ""
              }`}
              onClick={handleFilterAll}
            >
              所有賽事
            </div>
          </div>
          <div className="selected-tags-container">
            {selectedItem !== "所有項目" && (
              <div className="tag">
                {selectedItem}
                <span className="remove-tag" onClick={() => removeTag("item")}>
                  &times;
                </span>
              </div>
            )}
            {selectedTeam !== "所有隊伍" && (
              <div className="tag">
                {selectedTeam}
                <span className="remove-tag" onClick={() => removeTag("team")}>
                  &times;
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="schedule-filter-date">
          <HorizontalDatePicker
            dates={dates}
            onDateSelect={handleDateSelect}
            selectedDate={selectedDate}
          />
        </div>

        <div className="schedule-list">
          <ScheduleList data={filteredData} channelList={channelList} loading={loading} />
        </div>
      </div>
      {showFilters && (
        <div
          className="dim-overlay"
          onClick={() => setShowFilters(false)}
        ></div>
      )}
      <FilterOverlay
        show={showFilters}
        onClose={() => setShowFilters(false)}
        items={items}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        teamOptions={teamOptions}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        dates={dates}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  );
};

export default SchedulePage;
