import { UNKNOWN_VALUE } from '../Constants/tracking';
import { EpisodeType } from '../Constants/episode';

export const getVideoInfoFromVideo = (video) => {
  return {
    zh: [
      video?._id || UNKNOWN_VALUE,
      video?.zh_HK?.program_title || UNKNOWN_VALUE,
      video?.productType === EpisodeType.MASTER
        ? `第${video?.episodeNum ?? UNKNOWN_VALUE}集`
        : video.zh_HK.episodeNameU3 || UNKNOWN_VALUE,
    ].join(' '),
    en: [
      video?._id || UNKNOWN_VALUE,
      video?.en_HK?.program_title || UNKNOWN_VALUE,
      video?.productType === EpisodeType.MASTER
        ? `Episode No: ${video.episodeNum ?? UNKNOWN_VALUE}`
        : video.en_HK.episodeNameU3 || UNKNOWN_VALUE,
    ].join(' '),
  };
};

export const getEpisodeTrackingTitle = (episode) => {
  if (!episode) return null;

  return (
    episode.zh_HK?.episodeNameU3 ||
    episode.episodeNameU3 ||
    episode.en_HK?.episodeNameU3
  );
};
