import { useLayoutEffect, useRef } from 'react';

const usePlayerTracking = (props) => {
  const { playerRef, getPayloadFn, trackVideoTerminateFn } = props;

  const lastPositionRef = useRef();

  const getCurrentFormattedTimeRef = useRef(null);

  const onTime = (evt) => {
    lastPositionRef.current = evt.position;
  };

  const getTimestamp = (payload) => {
    // we store a ref of getCurrentFormattedTime in case the player removed
    // so can still access to getCurrentFormattedTime before its garbage collected
    const getCurrentFormattedTime =
      playerRef.current?.getPlayerProxy()?.getCurrentFormattedTime;

    if (getCurrentFormattedTime) {
      getCurrentFormattedTimeRef.current = getCurrentFormattedTime;
    }

    const timestamp = getCurrentFormattedTimeRef.current?.(
      payload?.time ?? lastPositionRef.current ?? undefined
    );

    return timestamp;
  };

  const getTrackingPayload = (payload) => {
    try {
      const trackingPayload = getPayloadFn(payload);
      if (!trackingPayload) return null;

      return {
        ...trackingPayload,
        video_timestamp: getTimestamp(payload) ?? '',
      };
    } catch (err) {
      console.error(
        err,
        `video::tracking:: Fail to get common tracking payload for video. User-Agent: ${navigator?.userAgent}`
      );
    }
    return null;
  };

  const effectParamsRef = useRef({ getTrackingPayload, trackVideoTerminateFn });
  effectParamsRef.current.getTrackingPayload = getTrackingPayload;
  effectParamsRef.current.trackVideoTerminateFn = trackVideoTerminateFn;

  useLayoutEffect(() => {
    const handleOnBeforeUnload = () => {
      const commonTrackingPayload =
        effectParamsRef.current.getTrackingPayload();
      if (commonTrackingPayload) {
        effectParamsRef.current.trackVideoTerminateFn?.();
      }
    };

    // event "pagehide" "unload" is not reliable to fire, beforeunload so far is the best option
    // event "visibilitychange" is already handled by the player's "onViewable" handler
    window.addEventListener('beforeunload', handleOnBeforeUnload, false);

    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload);
    };
  }, []);

  return {
    onTime,
    getTimestamp,
    getTrackingPayload,
    lastPositionRef,
  };
};

export default usePlayerTracking;
